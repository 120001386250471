import React, { Component } from 'react';
import { connect } from 'react-redux';
import UpdateCard from './UpdateCard';
import { Trans, translate } from 'react-i18next';
import { PanelHeader, Spinner } from '../../components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row
} from 'reactstrap';
import api from '../../helpers/api/index';
import { dateFormatter } from '../../helpers/formatters';
import { compose } from 'redux';

class Updates extends Component {
  state = {
    loading: false,
    filter: 0,
    updates: {
      total: 0,
      data: [],
      lastCheck: ''
    }
  };

  componentWillReceiveProps({ updates }) {
    this.setState({ updates });
  }

  componentWillMount() {
    let { updates } = this.props;
    this.setState({ updates });
  }

  filterNotifications(data) {
    let currents = [];
    try {
      Object.keys(data).forEach(entity => {
        data[entity].forEach(notification => {
          let same = currents.findIndex(
            x => x.item && x.item._id && x.item._id === notification.item._id
          );
          // if notification not exist create a new
          if (same === -1) {
            notification.amount = 1;
            notification.list = [notification];
            currents.push(notification);
          }
          // if notification exist for the same element update amount and list
          if (same !== -1) {
            currents[same].amount++;
            currents[same].createAt = notification.createAt;
            if (!currents[same].list) currents[same].list = [];
            currents[same].list.push(notification);
          }
        });
      });
    } catch (err) {}

    return currents;
  }

  filter(hours) {
    this.setState({ loading: true });

    api.Board.Notifications({ time: hours || 1 })
      .then(({ data }) => {
        let notfs = this.filterNotifications(data);
        this.setState({
          updates: {
            data: notfs,
            total: notfs.length,
            lastCheck: ''
          },
          filter: hours,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { loading, updates, filter } = this.state;

    return (
      <div>
        <PanelHeader size={'sm'} />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <Col>
                <Row>
                  <Button
                    color={filter === 1 ? 'info' : 'default'}
                    onClick={() => this.filter(1)}
                  >
                    <Trans>Last Hour</Trans>
                  </Button>
                  <Button
                    color={filter === 12 ? 'info' : 'default'}
                    onClick={() => this.filter(12)}
                  >
                    <Trans>12 Lasts Hours</Trans>
                  </Button>
                  <Button
                    color={filter === 24 ? 'info' : 'default'}
                    onClick={() => this.filter(24)}
                  >
                    <Trans>Last Day</Trans>
                  </Button>
                  <Button
                    color={filter === 168 ? 'info' : 'default'}
                    onClick={() => this.filter(168)}
                  >
                    <Trans>Last Week</Trans>
                  </Button>
                </Row>
                <Row>
                  {updates.total} <Trans>Updates</Trans>
                  <span className="float-right">
                    <small className={'ml-2'}>
                      <Trans>Last Check</Trans>:{' '}
                      {updates.lastCheck
                        ? ' - ' + dateFormatter(updates.lastCheck)
                        : null}
                    </small>
                  </span>
                </Row>
              </Col>
            </CardHeader>
            <CardBody>
              {updates.data.map((u, index) => {
                return (
                  <UpdateCard
                    key={'app-notif-' + index}
                    notification={u}
                    index={index}
                  />
                );
              })}
            </CardBody>
            <CardFooter>
              {updates.total} <Trans>Updates</Trans>
            </CardFooter>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { updates: state.updates };
};

export default compose(
  connect(mapStateToProps),
  translate('translations-fr')
)(Updates);
