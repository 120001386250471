import React from 'react';
import { Route, Switch } from 'react-router-dom';
import marketRoutes from '../../routes/market';

class Market extends React.Component {
  render() {
    return (
      <div className="projects_layout">
        <Switch>
          {marketRoutes.map((prop, key) => {
            return (
              <Route
                exact
                path={`${this.props.match.url}${prop.path}`}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default Market;
